import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "assets/vendor/nucleo/css/nucleo.css";
import "assets/vendor/font-awesome/css/font-awesome.min.css";
import "assets/scss/iiitk.scss?v1.1.0";

import Index from "views/Index.js";
import AboutInstitute from "views/HeaderSection/AboutUS/AboutInstitute";
import GoverningBody from "views/HeaderSection/Administration/GoverningBody";
import Partnership from "views/HeaderSection/AboutUS/Partnership";
import Webmasters from "views/HeaderSection/AboutUS/Webmasters";
import Visitor from "views/HeaderSection/Administration/visitor";
import Alumni from "views/HeaderSection/People/Alumni";
import Faculty from "views/HeaderSection/People/Faculty";
import OfficeStaff from "views/HeaderSection/People/OfficeStaff";
import Committees from "views/HeaderSection/People/Committees";
import Director from "views/HeaderSection/Administration/Director";
import Registrar from "views/HeaderSection/Administration/Registrar";
import Finance from "views/HeaderSection/Administration/FinanceCommitee";
import Senate from "views/HeaderSection/Administration/Senate";
import Statues from "views/HeaderSection/Administration/Statues";
import Ordinance from "views/HeaderSection/Administration/Ordinance";
import Fees from "views/HeaderSection/Admission/Fees";
import Procedure from "views/HeaderSection/Admission/Procedure";
import UgProcedure from "views/HeaderSection/Admission/UgProcedure";
import PgProcedure from "views/HeaderSection/Admission/PgProcedure";
import Scholarship from "views/HeaderSection/Admission/Scholarship";
import Calender from "views/HeaderSection/Academics/Calander";
import Examination from "views/HeaderSection/Academics/Examination";
import Courses from "views/HeaderSection/Academics/Courses";
import UndergraduateProgram from "views/HeaderSection/Academics/UndergraduateProgram";
import Curriculam from "views/HeaderSection/Academics/Curriculam";
import Department from "views/HeaderSection/Academics/Department";
import TimeTable from "views/HeaderSection/Academics/TimeTable";

import Cse from "views/HeaderSection/Academics/Departments/Cse";
import Ece from "views/HeaderSection/Academics/Departments/Ece";
import Ai_de from "views/HeaderSection/Academics/Departments/Ai_de";
// import WhyIIITK from "views/HeaderSection/Placement/WhyIIITK";
// import OurRecruiters from "views/HeaderSection/Placement/OurRecruiters";
// import TnP from "views/HeaderSection/Placement/TnP";
// import PlacementStats from "views/HeaderSection/Placement/PlacementStats";
import Achievements from "views/HeaderSection/Life/Achievements";
import Canteen from "views/HeaderSection/Life/Canteen";
import Clubs from "views/HeaderSection/Life/Clubs";
import ComputerLabs from "views/HeaderSection/Life/ComputerLabs";
import Events from "views/HeaderSection/Life/Events";
import Fests from "views/HeaderSection/Life/Fests";
import Gallery from "views/HeaderSection/Life/Gallery";
import Hostels from "views/HeaderSection/Life/Hostels";
import Library from "views/HeaderSection/Life/Library";
import Medical from "views/HeaderSection/Life/Medical";
import Bank from "views/HeaderSection/Life/Bank";
import PostOffice from "views/HeaderSection/Life/PostOffice";
import RulesandDisciplines from "views/HeaderSection/Life/RulesandDisciplines";
import Sports from "views/HeaderSection/Life/Sports";
import AnnualReports from "views/FooterLinks/AnnualReports";
import Campus from "views/FooterLinks/Campus";
import Holidays from "views/FooterLinks/Holidays";
import Iccw from "views/FooterLinks/Iccw";
import ItInfrastructure from "views/FooterLinks/ItInfrastructure";
import PppAct from "views/FooterLinks/PppAct";
import Recruitment from "views/FooterLinks/Recruitment";
import Rti from "views/FooterLinks/Rti";
import Sitemap from "views/FooterLinks/Sitemap";
import Students from "views/FooterLinks/Students";
import TenderInvitations from "views/FooterLinks/TenderInvitations";
import FacultyPage from "views/faculty/FacultyPage";
import BnWCommitee from "views/HeaderSection/Administration/BnWCommitee";
import Student from "views/Student/Student";
import LatestUpdate from "components/LatestUpdate";
import Announcement from "components/Announcement";
import Cse_news from "components/Cse_news";
import ExecutiveCommittee from "views/HeaderSection/Administration/ExecutiveCommittee";
import AdministratingBody from "views/HeaderSection/Administration/AdministratingBody";
import Coordinator from "views/HeaderSection/People/Coordinator";
import Deans from "views/HeaderSection/People/Deans";
// import PG from "views/HeaderSection/Admission/PG";
import ScrollTop from "components/ScrollTop";
import DirectorsMessage from "views/HeaderSection/AboutUS/DirectorsMessage";

import Error404 from "components/404";
import StudentsSection from "views/FooterLinks/students/StudentsSection";
import Convocation from "views/HeaderSection/Academics/convocation";
import Trai from "./views/HeaderSection/Life/Trai";
import AboutIIIT from "views/HeaderSection/AboutUS/AboutIIIT";
import Chairperson from "views/HeaderSection/Administration/Chairperson";
import Antiragging from "views/FooterLinks/Antiragging";

require("dotenv").config();

ReactDOM.render(
  <>
    <BrowserRouter>
      <Switch>
        <Route path="/" exact render={(props) => <Index {...props} />} />
        <Route
          path="/electronics-communication-eng"
          exact
          render={(props) => <Ece {...props} />}
        />
        <Route
          path="/AI&DE"
          exact
          render={(props) => <Ai_de {...props} />}
        />
        <Route
          path="/computer-science-eng"
          exact
          render={(props) => <Cse {...props} />}
        />
        <Route
          path="/latest-update"
          exact
          render={(props) => <LatestUpdate {...props} />}
        />
        <Route
          path="/announcement"
          exact
          render={(props) => <Announcement {...props} />}
        />

        <Route
          path="/cse_news"
          exact
          render={(props) => <Cse_news {...props} />}
        />

        {/* About us section  */}
        <Route
          path="/about-institute"
          exact
          render={(props) => <AboutInstitute {...props} />}
        />

        <Route
          path="/board-of-governors"
          exact
          render={(props) => <GoverningBody {...props} />}
        />
        
        <Route
          path="/partnership"
          exact
          render={(props) => <Partnership {...props} />}
        />

        <Route
          path="/webmasters"
          exact
          render={(props) => <Webmasters {...props} />}
        />

        <Route
          path="/visitor"
          exact
          render={(props) => <Visitor {...props} />}
        />

        <Route
          path="/directors-message"
          exact
          render={(props) => <DirectorsMessage {...props} />}
        />

        <Route
          path="/about-iiit"
          exact
          render={(props) => <AboutIIIT {...props} />}
        />

        {/* People */}
        <Route path="/alumni" exact render={(props) => <Alumni {...props} />} />
        <Route
          path="/faculty"
          exact
          render={(props) => <Faculty {...props} />}
        />
        <Route
          path="/office-staff"
          exact
          render={(props) => <OfficeStaff {...props} />}
        />
        <Route
          path="/committees"
          exact
          render={(props) => <Committees {...props} />}
        />
        {/* Administration */}
        <Route
          path="/building-work-committee"
          exact
          render={(props) => <BnWCommitee {...props} />}
        />
        <Route
          path="/chairperson"
          exact
          render={(props) => <Chairperson {...props} />}
        />
        <Route
          path="/director"
          exact
          render={(props) => <Director {...props} />}
        />
        <Route
          path="/registrar"
          exact
          render={(props) => <Registrar {...props} />}
        />
        <Route
          path="/finance-committee"
          exact
          render={(props) => <Finance {...props} />}
        />
        <Route
          path="/executive-committee"
          exact
          render={(props) => <ExecutiveCommittee {...props} />}
        />
        <Route
          path="/administration"
          exact
          render={(props) => <AdministratingBody {...props} />}
        />
        <Route path="/senate" exact render={(props) => <Senate {...props} />} />
        <Route
          path="/statues"
          exact
          render={(props) => <Statues {...props} />}
        />
        <Route
          path="/ordinance"
          exact
          render={(props) => <Ordinance {...props} />}
        />
        
        {/* Admission */}
        <Route path="/fees" exact render={(props) => <Fees {...props} />} />
        <Route
          path="/procedure"
          exact
          render={(props) => <Procedure {...props} />}
        />
        <Route
          path="/ug-procedure"
          exact
          render={(props) => <UgProcedure {...props} />}
        />
        <Route
          path="/pg-procedure"
          exact
          render={(props) => <PgProcedure {...props} />}
        />
        <Route
          path="/scholarship"
          exact
          render={(props) => <Scholarship {...props} />}
        />
        {/* Academics */}
        <Route
          path="/convocation"
          exact
          render={(props) => <Convocation {...props} />}
        />
        <Route
          path="/Calender"
          exact
          render={(props) => <Calender {...props} />}
        />
        <Route
          path="/examination-evaluation"
          exact
          render={(props) => <Examination {...props} />}
        />
        <Route
          path="/courses"
          exact
          render={(props) => <Courses {...props} />}
        />
        <Route
          path="/Curriculum"
          exact
          render={(props) => <Curriculam {...props} />}
        />
        <Route
          path="/department"
          exact
          render={(props) => <Department {...props} />}
        />
        <Route
          path="/time-table"
          exact
          render={(props) => <TimeTable {...props} />}
        />
        <Route
          path="/undergraduate-program"
          exact
          render={(props) => <UndergraduateProgram {...props} />}
        />
        
        {/* Placement */}
        {/* <Route
          path="/why-iiitkota"
          exact
          render={(props) => <WhyIIITK {...props} />}
        />
        <Route
          path="/our-recruiters"
          exact
          render={(props) => <OurRecruiters {...props} />}
        />
        <Route
          path="/traning-placement-cell"
          exact
          render={(props) => <TnP {...props} />}
        />
        <Route
          path="/placement-statistics"
          exact
          render={(props) => <PlacementStats {...props} />}
        /> */}
        {/* Life */}
        <Route
          path="/achievements"
          exact
          render={(props) => <Achievements {...props} />}
        />
        <Route
          path="/canteen"
          exact
          render={(props) => <Canteen {...props} />}
        />
        <Route path="/clubs" exact render={(props) => <Clubs {...props} />} />
        <Route
          path="/computer-labs"
          exact
          render={(props) => <ComputerLabs {...props} />}
        />
        <Route path="/events" exact render={(props) => <Events {...props} />} />
        <Route path="/fests" exact render={(props) => <Fests {...props} />} />
        <Route
          path="/gallery"
          exact
          render={(props) => <Gallery {...props} />}
        />
        <Route
          path="/hostels"
          exact
          render={(props) => <Hostels {...props} />}
        />
        <Route
          path="/library"
          exact
          render={(props) => <Library {...props} />}
        />
        <Route
          path="/medical"
          exact
          render={(props) => <Medical {...props} />}
        />
        <Route
          path="/rules-desciplines"
          exact
          render={(props) => <RulesandDisciplines {...props} />}
        />
        <Route path="/bank" exact render={(props) => <Bank {...props} />} />
        <Route
          path="/postoffice"
          exact
          render={(props) => <PostOffice {...props} />}
        />
        <Route path="/sports" exact render={(props) => <Sports {...props} />} />
        {/* Footer Links */}
        <Route
          path="/annual-reports"
          exact
          render={(props) => <AnnualReports {...props} />}
        />
        <Route path="/campus" exact render={(props) => <Campus {...props} />} />
        <Route
          path="/holidays"
          exact
          render={(props) => <Holidays {...props} />}
        />
        <Route path="/iccw" exact render={(props) => <Iccw {...props} />} />
        <Route
          path="/it-infrastructure"
          exact
          render={(props) => <ItInfrastructure {...props} />}
        />
         <Route
          path="/antiragging"
          exact
          render={(props) => <Antiragging {...props} />}
        />

       
        {/* <Route
          path="/ppp-act"
          exact
          render={(props) => <PppAct {...props} />}
        /> */}
        <Route
          path="/recruitment"
          exact
          render={(props) => <Recruitment {...props} />}
        />
        <Route path="/rti" exact render={(props) => <Rti {...props} />} />
        <Route
          path="/sitemap"
          exact
          render={(props) => <Sitemap {...props} />}
        />
        <Route
          path="/student/:id"
          exact
          render={(props) => <Student {...props} />}
        />
        <Route
          path="/students"
          exact
          render={(props) => <Students {...props} />}
        />
        <Route
          path="/students/:year"
          exact
          render={(props) => <StudentsSection {...props} />}
        />
        <Route
          path="/tender-invitations"
          exact
          render={(props) => <TenderInvitations {...props} />}
        />
        <Route
          path="/faculty/:id"
          render={(props) => <FacultyPage {...props} />}
        />
        <Route
          path="/coordinator"
          render={(props) => <Coordinator {...props} />}
        />
        <Route
          path="/deans"
          render={(props) => <Deans {...props} />}
        />
        <Route
          path="/convocation"
          render={(props) => <Convocation {...props} />}
        />
        <Route path="/trai-lab" render={(props) => <Trai {...props} />} />
        <Route render={(props) => <Error404 {...props} />} to="/404" />
        {/* <Route render={(props) => <Error404 {...props} />} /> */}
        <Redirect to="/" />
        {/* <Route path="/404" component={Error404} />*/}
        {/* <Redirect push to="/404" /> */}
      </Switch>
    </BrowserRouter>
    <ScrollTop />
  </>,
  document.getElementById("root")
);
